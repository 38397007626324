.approved-page {
  margin-left: 4rem;
}

.filter {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
}

.section {
  overflow: hidden;
  
}
.apprSpecTable {
  margin-top: 3rem;
  margin-right: 1rem;
}

.export-button-col {
  display: flex;
  justify-content: end;
  align-items: start;
}